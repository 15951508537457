import React, { FunctionComponent } from 'react'
import GenerateCdnUrl from 'utils/GenerateCdnUrl'

type Props = {
  fileName: string
  alt?: string
  className?: string
  height?: number
  width?: number
}

const CdnImage: FunctionComponent<Props> = (props) => {
  return <img height={props.height || undefined} width={props.width || undefined} className={props.className} src={GenerateCdnUrl(props.fileName)} alt={props.alt} />
}

export default CdnImage
