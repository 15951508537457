import CdnImage from 'components/core/CdnImage'
import { AnimatePresence, motion } from 'framer-motion'
import { MenuModel } from 'models/menu/MenuModel'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { ElementById } from 'utils/ElementById'
import { generateMenuIdFromSlug } from 'utils/GenerateMenuIdFromSlug'
import { HasClassName } from 'utils/HasClassName'
import cookie from 'js-cookie'

type Props = {
  menu: MenuModel
  isStatic?: boolean
}

const MainMenu: FunctionComponent<Props> = (props) => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false)
  const router = useRouter()
  const menu = props.menu.data
  const menuDataAttributes = menu.attributes
  const menuItems = menuDataAttributes.items
  const topHeaderId = 'header-top'
  const mainHeaderId = 'main-header'
  const fixedHeaderClass = 'fixed-header'
  const targetedEvent = 'scroll'
  const toggleNav = (isOpen: boolean): void => {
    setIsNavOpen(!isOpen)
  }

  useEffect(() => {
    const tapVidCookie = cookie.get('tap_vid')
    const cjCookie = cookie.get('cje')

    if (cjCookie) {
      cookie.remove('tap_vid')
      cookie.remove('tap_ref')

      const cj_aid_Cookie = cookie.get('cj_aid') ?? ''
      const cj_pid_Cookie = cookie.get('cj_pid') ?? ''

      let witopia_pricing_menu = document.getElementById('witopia_url')
      let witopia_url = witopia_pricing_menu?.getElementsByTagName('a')[0]
      if (witopia_url instanceof HTMLAnchorElement) {
        let witopia_url_href = new URL(witopia_url.href)

        if (witopia_url_href.searchParams.get('ref')) {
          witopia_url_href.searchParams.delete('ref')
        }

        if (!witopia_url_href.searchParams.get('cjevent')) {
          witopia_url_href.searchParams.append('cjevent', cjCookie)
          witopia_url_href.searchParams.append('AID', cj_aid_Cookie)
          witopia_url_href.searchParams.append('PID', cj_pid_Cookie)
          witopia_url.href = witopia_url_href.href
        }
      }
    }

    if (tapVidCookie) {
      cookie.remove('cje')
      cookie.remove('cj_aid')
      cookie.remove('cj_pid')
      const tapRefCookie = cookie.get('tap_ref')
      if (tapRefCookie) {
        let witopia_pricing_menu = document.getElementById('witopia_url')
        let witopia_url = witopia_pricing_menu?.getElementsByTagName('a')[0]
        if (witopia_url instanceof HTMLAnchorElement) {
          let witopia_url_href = new URL(witopia_url.href)

          if (witopia_url_href.searchParams.get('cjevent')) {
            witopia_url_href.searchParams.delete('cjevent')
            witopia_url_href.searchParams.delete('AID')
            witopia_url_href.searchParams.delete('PID')
          }

          if (!witopia_url_href.searchParams.get('ref')) {
            witopia_url_href.searchParams.append('ref', tapRefCookie)
            witopia_url.href = witopia_url_href.href
          }
        }
      }
    } else {
      cookie.remove('tap_ref')
    }
  }, [])

  useEffect(() => {
    const topHeaderElement = ElementById(topHeaderId)
    const mainHeaderElement = ElementById(mainHeaderId)
    const isClassInTopHeader = HasClassName(topHeaderElement, fixedHeaderClass)
    const isClassInMainHeader = HasClassName(mainHeaderElement, fixedHeaderClass)
    const isClassAdded = isClassInTopHeader && isClassInMainHeader
    const shouldAddClass = !isClassAdded

    if (props.isStatic) {
      topHeaderElement?.classList.add(fixedHeaderClass)
      mainHeaderElement?.classList.add(fixedHeaderClass)

      return
    }

    window.addEventListener(targetedEvent, () => {
      if (window.scrollY > 50 && shouldAddClass) {
        topHeaderElement?.classList.add(fixedHeaderClass)
        mainHeaderElement?.classList.add(fixedHeaderClass)
      } else {
        topHeaderElement?.classList.remove(fixedHeaderClass)
        mainHeaderElement?.classList.remove(fixedHeaderClass)
      }
    })

    return () => {}
  }, [])

  return (
    <Fragment>
      <header id="main-header" className={`${props.isStatic ? '' : ''} lg:block py-8 w-full z-20 hidden`}>
        <div className="container flex justify-between items-center">
          <div className="logo-wrap h-[70px]">
            <Link href="/">
              <a className="logo">
                <CdnImage width={196} height={54} className="xl:w-[196px] xl:h-auto lg:w-auto lg:h-[54px] transition-all" fileName="sme-logo-white.svg" alt="SecureMyEmail" />
              </a>
            </Link>
            <Link href="/">
              <a className="fixed-menu-logo invisible h-0 opacity-0">
                <CdnImage width={110} height={43} className="h-auto transition-all" fileName="sme-logo-updated.svg" alt="SecureMyEmail" />
              </a>
            </Link>
          </div>
          <nav className="flex flex-wrap items-center text-base justify-center">
            <ul className="flex">
              {menuItems.map((item, index) => (
                <li key={index} className="px-3" id={generateMenuIdFromSlug(item)}>
                  <Link href={item.href ?? '#'}>
                    <a
                      className={`xl:text-lg cursor-pointer text-sm font-semibold transition duration-200 ease-out hover:text-[#f06725] hover:ease-in sme-menu-link ${
                        router.asPath.startsWith(item.href) ? 'active' : 'text-white'
                      }`}
                    >
                      {item.title}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>
      <div className="mobile-custom-menu bg-white border-b-2 border-gray-100 lg:hidden py-3 px-4">
        <div className="flex justify-between items-center">
          <Link href="/">
            <a>
              <CdnImage width={120} height={47} alt="SecureMyEmail" fileName="SecureMyEmail_Logo_updated.svg" />
            </a>
          </Link>
          <button
            className="nav-toggler bg-[#ef6d25] rounded px-[10px] py-[9px] h-[36px]"
            onClick={() => {
              setIsNavOpen(!isNavOpen)
            }}
            data-target="#navigation"
          >
            <span className="bg-white relative w-[22px] h-[3px] text-[0px] block">Menu</span>
            <span className="bg-white relative w-[22px] h-[3px] text-[0px] block mt-1">Menu</span>
            <span className="bg-white relative w-[22px] h-[3px] text-[0px] block mt-1">Menu</span>
          </button>
        </div>
        <AnimatePresence>
          {isNavOpen && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition={{ type: 'tween' }}
              className="md:max-h-[520px] w-full text-center max-h-80 overflow-y-auto"
              id="navigation"
            >
              <ul className="w-full">
                {menuItems.map((item, index) => {
                  return (
                    <li className="px-3" key={index} id={item.htmlId ? item.htmlId : `menu-${item.href}`}>
                      <Link href={item.href}>
                        <div
                          onClick={() => setIsNavOpen(false)}
                          className="xl:text-lg cursor-pointer text-sm font-semibold text-[#f06725] py-3 block border-b border-[#f7f7f7] transition duration-200 ease-out hover:text-[#f06725] hover:ease-in"
                        >
                          {item.title}
                        </div>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Fragment>
  )
}

export default MainMenu
