import Button from 'components/core/Button'
import CdnImage from 'components/core/CdnImage'
import { MenuModel } from 'models/menu/MenuModel'
import Link from 'next/link'
import React, { Fragment, FunctionComponent } from 'react'
import { generateMenuIdFromSlug } from 'utils/GenerateMenuIdFromSlug'

type Props = {
  data: MenuModel
  isDark?: boolean
}

const Footer: FunctionComponent<Props> = (props) => {
  const propsData = props.data
  const attributes = propsData.data.attributes
  return (
    <Fragment>
      <footer className={`text-gray-600 ${attributes.styleClassNames} ${props.isDark && 'bg-[#222222]'}`}>
        <div className="container pt-24 flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
          <div className="flex-grow md:flex md:text-left text-center order-first">
            {attributes.items.map((item, index) => (
              <div key={index} className="lg:w-1/4 md:w-1/2 w-full px-4">
                <h4 className="text-[#f96400] font-lato text-sm uppercase mb-5 font-bold">{item.title}</h4>
                <ul className="list-none mb-10">
                  {item.links.map((link, index) => (
                    <Link key={index} href={link.href ?? '#'} passHref>
                      <li className="mb-[6px] cursor-pointer" id={generateMenuIdFromSlug(link)}>
                        <a href={link.href} className={`text-sm leading-[1.5] transition hover:text-[#c4c5c6] ${props.isDark ? 'text-white/80' : 'text-[#1c1f1f]'}`}>
                          {link.title}
                        </a>
                      </li>
                    </Link>
                  ))}
                </ul>
                <ul className="md:justify-start flex mb-[10px] justify-center gap-x-2">
                  {item.buttons.map((button, index) => (
                    <li key={index}>
                      <Button data={button} extraClassNames="w-5" />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {props.isDark ? (
              <Fragment>
                <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                  <h4 className="text-[#f96400] font-lato text-sm uppercase mb-5 font-bold">Social</h4>
                  <h5 className="text-white/80 text-sm leading-[1.5]">SecureMyEmail</h5>
                  <ul className="md:justify-start flex mb-[10px] justify-center gap-x-2">
                    <li className="hover:opacity-50">
                      <a href="https://www.facebook.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <CdnImage width={24} height={24} fileName="icon-social-facebook-light.svg" alt="Facebook" />
                      </a>
                    </li>
                    <li className="hover:opacity-50">
                      <a href="https://twitter.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <CdnImage width={24} height={24} fileName="icon-social-twitter-light.svg" alt="Twitter" />
                      </a>
                    </li>
                  </ul>
                  <h5 className="text-white/80 text-sm leading-[1.5]">Witopia</h5>
                  <ul className="md:justify-start flex mb-[10px] justify-center gap-x-2">
                    <li className="hover:opacity-50">
                      <a href="https://www.facebook.com/WiTopia" target="_blank" rel="noreferrer">
                        <CdnImage width={24} height={24} fileName="icon-social-facebook-light.svg" alt="Facebook" />
                      </a>
                    </li>
                    <li className="hover:opacity-50">
                      <a href="https://twitter.com/WiTopia" target="_blank" rel="noreferrer">
                        <CdnImage width={24} height={24} fileName="icon-social-twitter-light.svg" alt="Twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                  <h4 className="text-[#f96400] font-lato text-sm uppercase mb-5 font-bold">Social</h4>
                  <h5 className="text-[#1c1f1f] text-sm leading-[1.5]">SecureMyEmail</h5>
                  <ul className="md:justify-start flex mb-[10px] justify-center gap-x-2">
                    <li className="hover:opacity-50">
                      <a href="https://www.facebook.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <CdnImage width={24} height={24} fileName="icon-social-facebook.svg" alt="Facebook" />
                      </a>
                    </li>
                    <li className="hover:opacity-50">
                      <a href="https://twitter.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <CdnImage width={24} height={24} fileName="icon-social-twitter.svg" alt="Twitter" />
                      </a>
                    </li>
                  </ul>
                  <h5 className="text-[#1c1f1f] text-sm leading-[1.5]">Witopia</h5>
                  <ul className="md:justify-start flex mb-[10px] justify-center gap-x-2">
                    <li className="hover:opacity-50">
                      <a href="https://www.facebook.com/WiTopia" target="_blank" rel="noreferrer">
                        <CdnImage width={24} height={24} fileName="icon-social-facebook.svg" alt="Facebook" />
                      </a>
                    </li>
                    <li className="hover:opacity-50">
                      <a href="https://twitter.com/WiTopia" target="_blank" rel="noreferrer">
                        <CdnImage width={24} height={24} fileName="icon-social-twitter.svg" alt="Twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <div className="md:text-left text-[#1c1f1f] pb-8 block text-center">
          <div className="container px-8">
            Copyright © {new Date().getFullYear()} {attributes.copyrightText}
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default Footer
